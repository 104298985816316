.button-container {
  width: fit-content;
  height: 50px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0px 25px;
  font-size: 15px;
  background-color: black;
  color: white;
  text-transform: uppercase;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-weight: bolder;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  white-space: nowrap;

  &.reset-zoom {
    position: absolute;
    top: 3px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    height: 32px;
    line-height: 30px;
    padding: 0px 15px;
    background-color: rgb(39, 133, 88);

    &:hover {
      background-color: rgb(59, 153, 108);
    }
  }

  &.textPdf,
  &.export {
    background-color: rgb(54, 79, 129);
    border: none;
    align-items: center;
    gap: 6px;
    line-height: 14px;
    font-size: 12px;
    padding: 0px 8px;
    border-radius: 6px;
    height: 40px;

    &:hover {
      background-color: rgb(24, 99, 149);
    }
  }

  &.textPdf {
    margin-left: 8px;
  }
}
