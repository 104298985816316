.drbc-header {
  background-color: rgb(54, 79, 129);
  color: white;
  height: 100px;
  width: 100%;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
  margin-bottom: 8px;
  margin-top: 0px;
  display: flex;
  gap: 6px;

  .drbc-logo {
    height: 96px;
    margin: 2px;
    background-color: white;
  }
  
  hgroup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    h1 {
      font-size: 30px;
      margin: 0;
    }
  
    h2 {
      font-size: 18px;
      font-style: italic;
      margin: 0;
    }
  }
}

@media (max-width: 829px) {
  .drbc-header {
    hgroup {
      h1 {
        font-size: 20px;
      }

      h2 {
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 460px) {
  .drbc-header {
    hgroup {
      h1 {
        font-size: 16px;
      }

      h2 {
        font-size: 10px;
      }
    }
  }
}