.precip-table-container {
  background-color: #f7f7f7;
  padding: 10px 3px;

  .precip-table-content {
    background-color: white;
    border: 1px solid rgb(200, 200, 200);
    box-sizing: border-box;
  }
}
