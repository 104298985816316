$content-padding: 50px;
$content-width: calc(100% - $content-padding);
$bar-offset: 6px;
$bar-width: calc($content-width - $bar-offset);
$labels-width: calc($content-width - 27px);

.legend-container {
  display: flex;
  flex-direction: column;
  height: 48px;
  padding-top: 2px;
  padding-bottom: 6px;
  align-items: center;

  .legend-title {
    font-size: 14px;
    color: rgb(80,80,80);
    font-family: Verdana, Arial, Helvetica, sans-serif;
    position: relative;
    top: 2px;
  }

  .legend {
    width: 100%;
    position: relative;

    .legend-color-squares {
      display: flex;
      height: 15px;
      width: $bar-width;
      margin: 0 auto;
      border-top: 1px solid rgb(120, 120, 120);
      border-bottom: 1px solid rgb(120, 120, 120);

      .legend-color-square {
        height: 15px;
        flex-grow: 1;
        opacity: 0.6;
      }
    }

    .legend-ticks {
      display: flex;
      justify-content: space-between;
      width: $bar-width;
      position: absolute;
      left: calc(($content-padding + $bar-offset) / 2);
      bottom: -3px;

      .legend-tick {
        width: 1px;
        background-color: black;
        position: relative;

        &.long-tick {
          height: 20px;
          bottom: 3px;
        }

        &.short-tick {
          height: 17px;
          bottom: 0px;
        }
      }
    }

    .legend-labels {
      display: flex;
      justify-content: space-between;
      width: $labels-width;
      margin: 0 auto;
      margin-bottom: 2px;
      padding-left: 3px;
      font-size: 11px;
    }
  }
}
