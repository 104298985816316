.drbc-footer {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  background-color: rgb(54, 79, 129);
  color: white;
  min-height: 100px;
  height: fit-content;
  padding: 5px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;

  p {
    text-align: center;
    font-size: 10px;
    margin: 0;

    a:link,
    a:visited,
    a:active {
      color: rgb(174, 215, 235);
    }
    a:hover {
      color: white;
    }
  }
}