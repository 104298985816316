.chart-container {
  position: relative;
  overflow-y: hidden;

  .chart-buttons-container {
    position: absolute;
    bottom: -3px;
    display: flex;
    gap: 8px;
    align-items: center;
    left: 3px;
  }
}

.no-data {
  padding: 6px;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  font-style: italic;
  color: rgb(120, 120, 120);
}
