.drbc-layout {
  width: 100vw;
  overflow-y: scroll;
  height: 100vh;
  min-height: 700px;

  .drbc-content-container {
    background-color: white;
    max-width: 1200px;
    height: fit-content;
    min-height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    #drb-idf-main-container {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      
      .panel {
        width: 50%;
        display: flex;
        flex-direction: column;
      }

      .top-row {
        display: flex;
      }

      .bottom-row {
        display: flex;
        flex-grow: 1;
      }
    }
    
    #hidden-pdf-table {
      position: absolute;
      z-index: -1;
    }
  }
}

@media (max-width: 829px) {
  .drbc-layout {
    .drbc-content-container {
      #drb-idf-main-container {
        .panel {
          width: 80%;
          margin: 0 auto;
        }

        .bottom-row {
          flex-direction: column;
        }
      }
    }
  }
}