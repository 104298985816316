.download-btns-container {
  display: flex;
  gap: 8px;
  align-items: center;
  left: 3px;
}

.popper-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  padding: 6px;
  border: 1px solid rgb(220,220,220);
  border-radius: 5px;
}