$border: 1px solid rgb(220, 220, 220);

.location-info-container {
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding-bottom: 0px;
  border-top: $border;
  background-color: white;
  width: 100%;
  box-sizing: border-box;
  font-size: 12px;

  .location-info-hovered {
    padding: 4px;
    border-bottom: $border;
    display: flex;
    align-items: center;
    gap: 4px;

    .hovered-type {
      white-space: nowrap;
    }

    .hovered-name {
      text-align: center;
      width: 100%;
    }
  }
}
