.ci-toggles-container {
  width: fit-content;
  position: relative;
  bottom: 6px;

  .ci-toggles-label {
    font-size: 12px;
    color: #6e6e6e;
    text-align: center;
    position: relative;
    top: 5px;
  }
}
