#map-container {
  border-top: 1px solid rgb(180, 180, 180);
  border-bottom: 1px solid rgb(180, 180, 180);
  border-right: 1px solid rgb(180, 180, 180);
  position: relative;
  height: 100%;
  box-sizing: border-box;

  .mapboxgl-ctrl-attrib-button {
    background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg width=%2724%27 height=%2724%27 viewBox=%270 0 20 20%27 xmlns=%27http://www.w3.org/2000/svg%27 fill=%27silver%27 fill-rule=%27evenodd%27%3E%3Cpath d=%27M4 10a6 6 0 1 0 12 0 6 6 0 1 0-12 0m5-3a1 1 0 1 0 2 0 1 1 0 1 0-2 0m0 3a1 1 0 1 1 2 0v3a1 1 0 1 1-2 0%27/%3E%3C/svg%3E');
  }
}

@media (max-width: 829px) {
  #map-container {
    height: 500px;
    border-left: 1px solid rgb(180, 180, 180);
  }
}